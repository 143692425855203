import { call, fork, put, takeLeading } from "redux-saga/effects";
import {
    loadMatchPlayerVote,
    loadMatchPlayerVoteFailure,
    loadMatchPlayerVoteSuccess,
    resetPlayerSelection,
    selectPlayer,
    selectTicket,
    selectTicketFailure,
    selectTicketSuccess
} from "./reducer";
import { checkTicket, getVoteData } from "../../../api/transport/matchVoteTransport";
import { MatchPlayerVoteResponse, TicketCheckResult, VoteStatus } from "./types";

function* loadMatchPlayerVoteWatcher() {
    yield takeLeading(loadMatchPlayerVote.type, onLoadMatchVoteAsync);
}

function* onLoadMatchVoteAsync({ payload }: any) {
    try {
        const responseData: MatchPlayerVoteResponse = yield call(getVoteData);

        if (responseData.status === VoteStatus.CLOSED || responseData.status === VoteStatus.CREATED) {
            console.log('Match vote is closed or created');
            localStorage.removeItem('selectedTicket');
            yield put(resetPlayerSelection());
        } else {
            const eventId = localStorage.getItem("eventId") || '';
            if (+responseData.eventId !== +eventId) {
                console.log('EventIds', eventId, responseData.eventId);
                console.log('Event id is different');
                localStorage.removeItem('selectedTicket');
                localStorage.setItem("eventId", responseData.eventId.toString());
                yield put(resetPlayerSelection());
            }
        }

        yield put(loadMatchPlayerVoteSuccess({
           ...responseData,
        }));
    } catch (error: any) {
        yield put(loadMatchPlayerVoteFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

function* selectTicketWatcher() {
    yield takeLeading(selectTicket.type, selectTicketAsync);
}

function* selectTicketAsync({ payload }: any) {
    try {
        const responseData: boolean = yield call(checkTicket, payload);
        console.log(responseData);

        yield put(selectTicketSuccess(responseData ? TicketCheckResult.OK : TicketCheckResult.ERROR));
        // const responseData: MatchPlayerVoteResponse = yield call(getVoteData);
        // yield put(loadMatchPlayerVoteSuccess({
        //     ...responseData,
        // }));
    } catch (error: any) {
        yield put(selectTicketFailure(
            {
                error: 'Произошла ошибка, попробуйте повторить позднее'
            }
        ));
    }
}

function* selectPlayerWatcher() {
    yield takeLeading(selectPlayer.type, selectPlayerAsync);
}

function* selectPlayerAsync({ payload }: any) {
    yield localStorage.setItem('selectedPlayer', payload);
}

function* resetPlayerSelectionWatcher() {
    yield takeLeading(resetPlayerSelection.type, resetPlayerSelectionAsync);
}

function* resetPlayerSelectionAsync() {
    yield localStorage.removeItem('selectedPlayer');
}


export function* matchVoteSaga() {
    yield fork(loadMatchPlayerVoteWatcher);
    yield fork(selectTicketWatcher);
    yield fork(resetPlayerSelectionWatcher);
    yield fork(selectPlayerWatcher);
}