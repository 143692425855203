import React, { FC } from 'react';
import { BottomMenu } from '../components/BottomMenu';
import logo from '../logo.svg';
import { NewsIcon, ShopIcon, TicketIcon } from './Icons';

interface MobileLayoutProps {
    children: React.ReactNode;
    top?: React.ReactNode;
}

const MobileLayout: FC<MobileLayoutProps> = ({
    children,
    top,
}) => {
    return (
        <div className="min-h-screen h-screen bg-gray-100 min-w-80 relative">
            {/* Header */}
            {top}
            <header className="absolute top-0 left-0 right-0 bg-sky-950 text-white px-4 py-2 flex flex-row gap-3 justify-start items-center">
                <img src={logo} alt="ФК Родина" className="w-12 h-12" />
                <div>
                    <h1 className="text-xl font-semibold">РОДНЫЕ</h1>
                    <h3 className="text-sm font-light">Клуб болельщиков ФК «Родина»</h3>
                </div>
            </header>

            {/* Main Content */}
            <main className="p-4 space-y-6 py-16 flex justify-center items-center w-full h-full mx-auto">
                {children}
            </main>

            {/* Bottom Navigation */}
            <BottomMenu items={[
                { title: 'Билеты', href: 'https://fcrodina.com/ticket-front/', icon: <TicketIcon /> },
                { title: 'Магазин', href: 'https://shop.fcrodina.com', icon: <ShopIcon /> },
                { title: 'Новости', href: 'https://t.me/fcrodina', icon: <NewsIcon /> },
            ]} />
        </div>
    );
};

export default MobileLayout;
