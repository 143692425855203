import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { PlayerSelect } from "./PlayerSelect";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { loadMatchPlayerVote, selectTicket } from "../../store/slices/matchPlayerVote/reducer";
import { selectMatchPlayerVote } from "../../store/selectors";
import { VoteStatus } from "../../store/slices/matchPlayerVote/types";
import { VoteSent } from "./VoteSent";
import { TimerCard } from "./TimerCard";

interface MatchMVPVotePageProps { }

export const MatchMVPVotePage: React.FC<MatchMVPVotePageProps> = () => {
    const [timerText, setTimerText] = useState('');
    const [timerInterval, setTimerInterval] = useState<any>(0);
    const { page } = useParams<{ page: string }>();
    const navigate = useNavigate();
    const voteStart = () => {
        navigate("/vote/select");
    }
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadMatchPlayerVote({}));
        const polling = setInterval(() => {
            dispatch(loadMatchPlayerVote({}));
        }, 60 * 1000);

        return () => {
            clearInterval(polling);
        }
    }, [dispatch]);

    useEffect(() => {
        const ticket = localStorage.getItem("selectedTicket");
        if (ticket) {
            dispatch(selectTicket(ticket));
        }
    }, [dispatch]);

    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);
    const onTimerUpdate = (targetTime: number = 0) => {
        const now = Date.now();
        let diff = targetTime - now;
        if (diff < 0) {
            setTimerText("считанные секунды");
        } else {
            const days = Math.floor(diff / 86400000);
            diff -= days * 86400000;
            const hours = Math.floor((diff % 86400000) / 3600000);
            diff -= hours * 3600000;
            const minutes = Math.floor((diff % 3600000) / 60000);
            diff -= minutes * 60000;
            const seconds = Math.floor((diff % 60000) / 1000);

            let text = "";

            if (days > 0) {
                text = `${days}д ${hours}:`;
            } else if (hours > 0) {
                text = `${hours}:`;
            }

            if (hours > 0 || days > 0) {
                text += `${minutes > 9 ? minutes : `0${minutes}`}:`;
            } else {
                text = `${minutes}:`;
            }

            text += `${seconds > 9 ? seconds : `0${seconds}`}`;

            setTimerText(text);
        }
    }

    useEffect(() => {
        if (matchPlayerVote.status === VoteStatus.CREATED) {
            if (matchPlayerVote.startDate) {
                clearInterval(timerInterval);
                let nextUpdate = 0;
                if (matchPlayerVote.startDate > Date.now()) {
                    nextUpdate = matchPlayerVote.startDate - Date.now();
                } else {
                    nextUpdate = 60 * 1000;
                }

                setTimeout(() => {
                    dispatch(loadMatchPlayerVote({}));
                }, nextUpdate);

                onTimerUpdate(matchPlayerVote.startDate);
                const interval = setInterval(() => {
                    onTimerUpdate(matchPlayerVote.startDate);
                }, 1000);
                setTimerInterval(interval);
            }
        } else if (matchPlayerVote.status === VoteStatus.STARTED) {
            if (matchPlayerVote.endDate) {
                setTimeout(() => {
                    //dispatch(loadMatchPlayerVote({}));
                }, (matchPlayerVote.endDate || 0) - Date.now());
                clearInterval(timerInterval);
                onTimerUpdate(matchPlayerVote.endDate || 0);
                const interval = setInterval(() => {
                    onTimerUpdate(matchPlayerVote.endDate || 0);
                }, 1000);
                setTimerInterval(interval);
            }
        }

        return () => {
            clearInterval(timerInterval);
        }
    }, [matchPlayerVote]);

    useEffect(() => {
        if (matchPlayerVote.status === VoteStatus.CLOSED || matchPlayerVote.status === VoteStatus.CREATED) {
            if (!!page) {
                navigate("/vote");
            }
        } else if (matchPlayerVote.status === VoteStatus.STARTED) {
            if (page !== "select" && page !== "sent") {
                navigate("/vote/select");
            }
        } else if (matchPlayerVote.status === VoteStatus.WAITING_RESULTS) {
            if (page !== "wait-result") {
                navigate("/vote/wait-result");
            }
        } else if (matchPlayerVote.status === VoteStatus.COMPLETE) {
            if (page !== "result") {
                navigate("/vote/result");
            }
        }
    }, [matchPlayerVote.endDate, matchPlayerVote.status, navigate, page]);

    const isMyTicket = matchPlayerVote.result?.ticketId === matchPlayerVote.selectedTicket;

    const voteTimeIsOver = matchPlayerVote.endDate && matchPlayerVote.endDate < Date.now();

    const endVoteTimerCard = <TimerCard
        timeText={timerText}
        title="До конца голосования"
    />;

    if (voteTimeIsOver && matchPlayerVote.status === VoteStatus.STARTED && page !== "wait-result") {
        //navigate("/vote/wait-result");
    }

    return (
        <section className="w-full grid grid-cols-1 gap-4 max-w-sm">
            {!page && (
                <>
                    <div>
                        <h1 className="text-xl text-center font-bold">Лучший игрок матча</h1>
                        {matchPlayerVote.matchName && <h1 className="text-xl text-center font-bold">«{matchPlayerVote.matchName}»</h1>}
                    </div>
                    <img src="https://fcrodina.com/uploads/5242400d667553e4a8606442044f241e.jpg" alt="ФК Родина" className="w-full h-48 object-cover rounded-sm object-left" />
                    <Button title="Голосовать" onClick={voteStart} disabled={matchPlayerVote.status !== VoteStatus.STARTED} />
                    {(matchPlayerVote.status === VoteStatus.CLOSED || (matchPlayerVote.status === VoteStatus.CREATED/* && !matchPlayerVote.startDate*/)) &&
                        <div className="text-center font-semibold">
                            <div className="text-center">
                                Голосование начнется не позже 75-й минуты матча.
                            </div>
                            <div className="text-center">
                                Выбирай игрока и получишь шанс лично вручить ему приз. <a href="https://fcrodina.com/matchday/" className="text-blue-500">Подробнее</a>
                            </div>
                        </div>}
                    {matchPlayerVote.status === VoteStatus.CREATED && <div>
                        <TimerCard
                            timeText={timerText}
                            title="До начала голосования"
                        />
                    </div>}
                </>
            )}
            {page === "select" && (
                <PlayerSelect>
                    {!voteTimeIsOver && endVoteTimerCard}
                </PlayerSelect>
            )}
            {page === "sent" && (
                <VoteSent>
                    {!voteTimeIsOver && endVoteTimerCard}
                </VoteSent>
            )}
            {page === "result" && (
                <>
                    <Card>
                        <h1 className="text-xl text-center">Лучшим игроком выбран</h1>
                        <p className="text-center">{matchPlayerVote.result?.winner}</p>
                    </Card>
                    <Card>
                        {!isMyTicket && matchPlayerVote.result?.ticketId && <p className="text-center">Награду вручит зритель с билетом №{matchPlayerVote.result?.ticketId}</p>}
                        {isMyTicket &&
                            <>
                                <p className="text-center">
                                    <h1 className="font-bold">Поздравляем!</h1>
                                    <br />
                                    Твой игрок стал лучшим, а ты получил право вручить ему приз на следующем матче. Не забудь оформить на него <a className="text-blue-500" href="https://moscow.qtickets.events/132616-rodina-spartak-kostroma" target="_blank" rel="noreferrer noopener">билет</a>
                                    <br />
                                    <br />
                                    По дальнейшим действиям с тобой по номеру <span className="font-bold">{localStorage.getItem("phoneNumber")}</span> свяжется наш менеджер.
                                    <br />
                                    <br />
                                </p>
                                <p className="text-xl text-center">
                                    #топизародину
                                </p>
                            </>
                        }
                    </Card>
                </>
            )}
            {page === "wait-result" && (
                <Card>
                    <h1 className="text-xl text-center font-bold mb-4">Ожидаем результатов</h1>
                    <p className="text-center">Результаты голосования будут объявлены в ближайшее время</p>
                </Card>
            )}
        </section>
    );
}