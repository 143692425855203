export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || process.env.NEXT_PUBLIC_BACKEND_URL;
const TRANSPORT_BASE_URL = `${BACKEND_URL}/my-app/match-player-vote`;

export const getVoteData = async () => {
    const voteData = await fetch(TRANSPORT_BASE_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return voteData.json();
}

export const voteForPlayer = async (playerId: string, ticketId: string, phone: string, contestUuid: string) => {
    const voteData = await fetch(`${TRANSPORT_BASE_URL}/vote`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            playerId,
            ticketId,
            phone,
        }),
    });

    return voteData.json();
}

export const checkTicket = async (ticketId: string) => {
    const ticketData = await fetch(`${TRANSPORT_BASE_URL}/check-ticket/${ticketId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return ticketData.json();
}