import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MatchPlayerVoteDto, VoteStatus, TicketCheckResult } from './types';
//import { TeamConfig, TournamentConfig } from '../../../types';

// Define a type for the slice state
export interface MatchPlayerVoteState extends MatchPlayerVoteDto {
    loading?: boolean;
    ticketChecking?: boolean;
    ticketCheckResult: TicketCheckResult;
}

// Define the initial state using that type
const initialState: MatchPlayerVoteState = {
    uuid: '',
    status: VoteStatus.CLOSED,
    eventId: 0,
    matchName: '',
    candidates: [],
    selectedPlayer: '',
    selectedTicket: '',
    loading: false,
    ticketChecking: false,
    ticketCheckResult: TicketCheckResult.UNKNOWN,
};

const matchPlayerVote = createSlice({
    name: 'matchPlayerVote',
    initialState,
    reducers: {
        loadMatchPlayerVote: (state, action: PayloadAction<{}>) => {
            state.loading = true;
        },
        loadMatchPlayerVoteSuccess: (state, action: PayloadAction<MatchPlayerVoteDto>) => {
            state.uuid = action.payload.uuid;
            state.matchName = action.payload.matchName;
            state.candidates = action.payload.candidates;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            /*console.log('state.status', state.status);
            console.log('state.endDate', state.endDate);
            console.log('Date.now()', Date.now());
            if (action.payload.status === VoteStatus.STARTED && state.endDate && state.endDate < Date.now()) {
                state.status = VoteStatus.WAITING_RESULTS;
            } else {

            }*/
            state.status = action.payload.status;
            state.result = action.payload.result;
            state.loading = false;
        },
        loadMatchPlayerVoteFailure: (state, action: PayloadAction<{}>) => {
            state.loading = false;
        },
        selectTicket: (state, action: PayloadAction<string>) => {
            state.ticketChecking = true;
            state.selectedTicket = action.payload;
        },
        selectTicketSuccess: (state, action: PayloadAction<TicketCheckResult>) => {
            state.ticketCheckResult = action.payload;
            state.ticketChecking = false;
        },
        selectTicketFailure: (state, action: PayloadAction<{}>) => {
            state.ticketChecking = false;
            state.ticketCheckResult = TicketCheckResult.ERROR;
        },
        selectPlayer: (state, action: PayloadAction<string>) => {
            state.selectedPlayer = action.payload;
        },
        resetPlayerSelection: (state) => {
            state.selectedPlayer = '';
        }
    }
})

export const {
    loadMatchPlayerVote,
    loadMatchPlayerVoteSuccess,
    loadMatchPlayerVoteFailure,
    selectTicket,
    selectTicketSuccess,
    selectTicketFailure,
    selectPlayer,
    resetPlayerSelection,
} = matchPlayerVote.actions;

export default matchPlayerVote.reducer;