import { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import MobileLayout from './layout/MobileLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TestPage } from './pages/TestPage';
import { MatchMVPVotePage } from './pages/mvp-vote';
import { LaunchScreen } from './components/LaunchScreen';


const App = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showLaunchScreen, setShowLaunchScreen] = useState(true);
    const [hasToken, setHasToken] = useState(false);

    useEffect(() => {
        const handler = (event: Event) => {
            event.preventDefault();
            setDeferredPrompt(event);  // Save the event
            setIsInstallable(true);    // Show the install button
        };

        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('access_token')) {
            const accessToken = searchParams.get('access_token');
            localStorage.setItem('access_token', accessToken as string);
            window.location.search = '';
        }

        if (localStorage.getItem('access_token') === 'my-rodina-ui') {
            setHasToken(true);
            setTimeout(() => {
                setShowLaunchScreen(false);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            }, 3000);
        }

        window.addEventListener('beforeinstallprompt', handler);

        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    const handleInstallClick = () => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            }
        });
        if (deferredPrompt) {
            (deferredPrompt as any).prompt();  // Show the install prompt
            (deferredPrompt as any).userChoice.then((choiceResult: { outcome: string }) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
                setIsInstallable(false);
            });
        }
    };

    const installBanner = isInstallable && (
        <div className="w-full p-2 px-3 bg-sky-900 flex flex-row gap-2 justify-center items-center" onClick={handleInstallClick}>
            <div className="w-16 rounded-md bg-white p-1">
                <img src={logo} className="w-full" alt="logo" />
            </div>
            <div className='w-full flex flex-row items-center'>
                <div className='text-white p-2'>
                    Добавь на домашний экран
                </div>
                <button className="min-w-28 h-8 bg-white opacity-95 text-center rounded-md">
                    Установить
                </button>
            </div>
        </div>
    );

    return (
        <>
            {hasToken && <>
                {installBanner}
                <MobileLayout>
                    <Routes>
                        <Route path="/vote" element={<MatchMVPVotePage />} >
                            <Route path=":page" element={<MatchMVPVotePage />} />
                        </Route>
                        {/*<Route path="/" element={<TestPage />} />*/}
                        <Route path="/test" element={<TestPage />} />
                        <Route path="/test2" element={<div className="App">
                            <header className="App-header">
                                <img src={logo} className="App-logo" alt="logo" />
                            </header>
                        </div>} />
                        <Route path="*" element={<Navigate to="/vote" replace />} />
                    </Routes>
                </MobileLayout>
            </>}
            {loading && <LaunchScreen fade={!showLaunchScreen} />}
        </>
    );
};

export default App;
